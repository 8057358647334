import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

export default function Approach() {
  const data = useStaticQuery(graphql`
    {
      sanityHomepage {
        approach {
          heading
          subheading
          boxCard {
            title
            para
            icon {
              asset {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `);
  const { heading, subheading } = data.sanityHomepage.approach;
  const allApproaches = data.sanityHomepage.approach.boxCard;

  return (
    <>
      <div className="our-approach">
        <div className="wrapper centered">
          <div className="heading">
            <span className="tag">Our Approach</span>
            <h2>{heading}</h2>
            <p className="mono">{subheading}</p>
          </div>

          <div className="all-approaches all-boxes-sec">
            {allApproaches.map((approach, i) => (
              <div className="approach box-sec" key={i}>
                <div className="content">
                  <h3>{approach.title}</h3>
                  <p>{approach.para}</p>
                  <div className="img">
                    <GatsbyImage
                      image={approach.icon.asset.gatsbyImageData}
                      alt={approach.title}
                      className="invert-color"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
