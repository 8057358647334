import React, { useRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
/* eslint-disable */
import { Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
/* eslint-enable */

import { GatsbyImage } from 'gatsby-plugin-image';
import ArrowLeft from '../../images/ico-arrow-left.svg';

export default function Reviews() {
  const data = useStaticQuery(graphql`
    {
      allSanityReviews(sort: { fields: order, order: ASC }) {
        nodes {
          name
          occupation
          company
          quote
          pic {
            asset {
              gatsbyImageData
            }
          }
        }
      }
    }
  `);
  const allClients = data.allSanityReviews.nodes;
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  return (
    <>
      <div className="customer-reviews">
        <div className="wrapper">
          <Swiper
            className="review-swiper"
            modules={[Navigation, Autoplay]}
            grabCursor
            speed={600}
            autoplay={{ delay: 10000, disableOnInteraction: false }}
            navigation={{
              prevEl: prevRef.current,
              nextEl: nextRef.current,
            }}
            onBeforeInit={(swiper) => {
              swiper.params.navigation.prevEl = prevRef.current;
              swiper.params.navigation.nextEl = nextRef.current;
            }}
            loop
          >
            {allClients.map((client, i) => (
              <SwiperSlide className="review" key={i}>
                <div className="left">
                  <div className="pic">
                    <div className="quote-bg" />
                    <div className="img">
                      <GatsbyImage
                        image={client.pic.asset.gatsbyImageData}
                        alt={client.name}
                      />
                    </div>
                    <div className="quote-mark">
                      <svg
                        viewBox="0 0 30 30"
                        fill="#0B1823"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M13.8008 19.9961C13.8008 15.9805 11.3077 13.5525 8.10239 13.5525C7.12298 13.5525 6.32165 13.7393 5.60935 14.0195C5.60935 10.751 8.28047 8.22958 12.1091 7.48249V3C4.98609 3.93386 0 10.0039 0 17.8482C0 23.4514 3.02727 27 7.21202 27C11.1297 27 13.8008 24.1051 13.8008 19.9961ZM30 19.9961C30 15.9805 27.4179 13.5525 24.2126 13.5525C23.2332 13.5525 22.4318 13.7393 21.7195 14.1128C21.7195 10.751 24.4797 8.22958 28.3083 7.48249V3C21.1853 3.93386 16.1992 10.0039 16.1992 17.8482C16.1992 23.4514 19.2265 27 23.4112 27C27.3289 27 30 24.1051 30 19.9961Z" />
                      </svg>
                    </div>
                  </div>

                  <div className="left-info">
                    <h3>{client.name}</h3>
                    <p>
                      <span>{client.occupation},</span>
                      <span>{client.company}</span>
                    </p>
                  </div>
                </div>

                <div className="content">
                  <p className="comment">{client.quote}</p>

                  <div className="info">
                    <h3>{client.name}</h3>
                    <p>
                      <span>{client.occupation},</span>
                      <span>{client.company}</span>
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            ))}

            <div className="arrows">
              <button className="icon prev" type="button" ref={prevRef}>
                <img src={ArrowLeft} alt="prev" />
              </button>
              <button className="icon next" type="button" ref={nextRef}>
                <img src={ArrowLeft} alt="next" />
              </button>
            </div>
          </Swiper>
        </div>
      </div>
    </>
  );
}
