import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { PopupButton } from '@typeform/embed-react';

export default function Structures() {
  const data = useStaticQuery(graphql`
    {
      sanityHomepage {
        structures {
          heading
          subheading
          structure {
            title
            para
            tagline
            icon {
              asset {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `);
  const { heading, subheading } = data.sanityHomepage.structures;
  const allStructures = data.sanityHomepage.structures.structure;

  return (
    <>
      <div className="structures">
        <div className="wrapper centered">
          <div className="heading">
            <h2>{heading}</h2>
            <p className="mono">{subheading}</p>
          </div>

          <div className="all-structures">
            {allStructures.map((structure, i) => (
              <div className="structure" key={i}>
                <div className="img">
                  <GatsbyImage
                    image={structure.icon.asset.gatsbyImageData}
                    alt={structure.title}
                    className="invert-color"
                  />
                </div>
                <div className="content">
                  <h3>{structure.title}</h3>
                  <div className="tagline">{structure.tagline}</div>
                  <p>{structure.para}</p>
                </div>
              </div>
            ))}
          </div>

          <div className="cta">
            <PopupButton id="uvQcDWjQ" className="button">
              Hiring? Get Started
            </PopupButton>
          </div>
        </div>
      </div>
    </>
  );
}
